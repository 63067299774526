import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { ILogger } from './app/common/contracts/logging/logger';
import { environment } from './environments/environment';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    logger: ILogger;
  }
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
