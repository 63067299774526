import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defaultConfig, ICoreConfig } from '../common/config/core-config.token';

export class ConfigNotFoundException extends Error {}

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private _configCache: ICoreConfig;

  constructor(private http: HttpClient) {}

  getTenantConfig(): Promise<ICoreConfig> | never {
    return new Promise<ICoreConfig>((resolve, reject) => {
      if (this._configCache) {
        resolve(this._configCache);
      } else {
        this.http.get<any[]>(`/config`).subscribe({
          next: value => {
            if (value && value.length > 0) {
              this._configCache = { ...defaultConfig, ...value[0] };

              resolve(this._configCache);
            } else {
              reject('Config not found!');
            }
          },
          error: reason => reject(reason),
        });
      }
    });
  }
}
