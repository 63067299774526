import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';

import { PromptComponent } from '../../prompt/prompt.component';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  public promptEvent: any;

  constructor(private dialog: MatDialog, public platform: Platform, private router: Router) {}

  init() {
    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.promptEvent = event;
        this.openPromptComponent('android');
      });
    }
    if (this.platform.IOS) {
      const isInStandaloneMode = 'standalone' in window.navigator && window.navigator['standalone'];
      if (!isInStandaloneMode) {
        this.openPromptComponent('ios');
      }
    }
  }

  get installEvent() {
    return this.promptEvent;
  }

  private openPromptComponent(mobileType: 'ios' | 'android') {
    timer(500)
      .pipe(take(1))
      .subscribe(() => {
        if (mobileType === 'ios' || (mobileType === 'android' && this.router.url === '/welcome')) {
          this.dialog.open(PromptComponent, {
            data: { mobileType, promptEvent: this.promptEvent },
            panelClass: 'no-padding-dialog',
          });
        }
      });
  }
}
