import { Injectable } from '@angular/core';
import { isNumber } from 'lodash';
import { Conversation, Participant, Message } from '@twilio/conversations';
import { ChatChannel, ChatMember, ChatMessage } from '../data/db-schema';

@Injectable({
  providedIn: 'root',
})
export class TwilioToChatDtoHelpersService {
  private readonly _pushMarker = new RegExp(/^([0-9a-fA-F]{9})\|P#/);

  public convertToChatChannel(conversation: Conversation): ChatChannel {
    return {
      sid: conversation.sid,
      dateUpdated: conversation.dateUpdated.toISOString(),
      dateCreated: conversation.dateCreated.toISOString(),
      friendlyName: conversation.friendlyName || '',
      uniqueName: conversation.uniqueName || '',
      lastMessageIndex: conversation.lastMessage ? conversation.lastMessage.index : null,
      lastMessageTimestamp: conversation.lastMessage
        ? conversation.lastMessage.dateCreated.toISOString()
        : conversation.dateCreated.toISOString(),
      lastLocalUpdateAt: undefined,
      lastConsumedMessageIndex: conversation.lastReadMessageIndex || null,
      removed: false,
      isOpen: conversation.attributes['isOpen'],
    };
  }

  public async convertToChatMessage(message: Message): Promise<ChatMessage> {
    const body = this.removePushMarkers(message.body);

    return {
      _id: message.attributes['_id'],
      isLocal: undefined,
      channelSid: message.conversation.sid,
      sid: message.sid,
      dateUpdated: message.dateUpdated.toISOString(),
      timestamp: message.dateCreated.toISOString(),
      index: message.index,
      body,
      memberSid: message.participantSid,
      memberIdentity: (await message.getParticipant()).identity,
      status: 'sent',
      retries: 0,
      attributes: message.attributes,
    };
  }

  public convertToChatMember(participant: Participant): ChatMember {
    return {
      sid: participant.sid,
      channelSid: participant.conversation.sid,
      identity: participant.identity,
      lastConsumedMessageIndex: isNumber(participant.lastReadMessageIndex) ? participant.lastReadMessageIndex : 0,
      dateUpdated: participant.dateUpdated.toISOString(),
      isLocal: undefined,
    };
  }

  /*
  To enable filtering of Push-Notifications, we will add a marker prefix to any message.
  Prefix: <last 9 characters of the agents uuid>|P#
  The "P" marks the PatientApp.
  */
  public enrichMessageWithPushMarkers(conversation: Conversation, body: string) {
    if ((conversation.attributes as any)?.assignedToId) {
      body = `${(conversation.attributes as any).assignedToId.slice(-9)}|P#${body}`;
    }

    return body;
  }

  public removePushMarkers(body: string) {
    // remove first 12 characters if they are UUID and PatientApp markers
    if (this._pushMarker.test(body)) {
      body = body.substring(12);
    }

    return body;
  }
}
