import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class MaterialIconService {
  constructor(private _sanitizer: DomSanitizer, private _matIconRegistry: MatIconRegistry) {
    console.log('Material Icon Service Registering Icons...');
    const origin = window.location.origin;
    this._matIconRegistry.addSvgIcon(
      'menu_orders',
      this._sanitizer.bypassSecurityTrustResourceUrl(origin + '/assets/icons/menu_orders.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'menu_chat',
      this._sanitizer.bypassSecurityTrustResourceUrl(origin + '/assets/icons/menu_chat.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'menu_dashboard',
      this._sanitizer.bypassSecurityTrustResourceUrl(origin + '/assets/icons/menu_dashboard.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'logout-icon',
      this._sanitizer.bypassSecurityTrustResourceUrl(origin + '/assets/icons/logout-icon.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'chat_video',
      this._sanitizer.bypassSecurityTrustResourceUrl(origin + '/assets/icons/chat_video.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'chat_live',
      this._sanitizer.bypassSecurityTrustResourceUrl(origin + '/assets/icons/chat_live.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'chat_online',
      this._sanitizer.bypassSecurityTrustResourceUrl(origin + '/assets/icons/chat_online.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'chat_offline',
      this._sanitizer.bypassSecurityTrustResourceUrl(origin + '/assets/icons/chat_offline.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'chevron',
      this._sanitizer.bypassSecurityTrustResourceUrl(origin + '/assets/icons/chevron.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'chat_dashboard',
      this._sanitizer.bypassSecurityTrustResourceUrl(origin + '/assets/icons/chat_dashboard.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'pwa_prompt',
      this._sanitizer.bypassSecurityTrustResourceUrl(origin + '/assets/icons/pwa_prompt.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'menu_documents',
      this._sanitizer.bypassSecurityTrustResourceUrl(origin + '/assets/icons/menu_documents.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'contact-data',
      this._sanitizer.bypassSecurityTrustResourceUrl(origin + '/assets/icons/contact-data.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'contact-data_email',
      this._sanitizer.bypassSecurityTrustResourceUrl(origin + '/assets/icons/contact-data_email.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'contact-data_phone',
      this._sanitizer.bypassSecurityTrustResourceUrl(origin + '/assets/icons/contact-data_phone.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'contact-data_fax',
      this._sanitizer.bypassSecurityTrustResourceUrl(origin + '/assets/icons/contact-data_fax.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'contact-data_faq',
      this._sanitizer.bypassSecurityTrustResourceUrl(origin + '/assets/icons/contact-data_faq.svg')
    );
    this._matIconRegistry.addSvgIcon(
      'agents_offline',
      this._sanitizer.bypassSecurityTrustResourceUrl(origin + '/assets/icons/agents_offline.svg')
    );
  }

  public registerSvgIcon(iconName: string) {
    this._matIconRegistry.addSvgIcon(
      iconName,
      this._sanitizer.bypassSecurityTrustResourceUrl(`${origin}/assets/icons/${iconName}.svg`)
    );
  }
}
