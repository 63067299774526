import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import makeDebug from 'src/makeDebug';

const debug = makeDebug('services:chat:online-status');

type State = 'connecting' | 'connected' | 'disconnecting' | 'disconnected' | 'denied';

@Injectable({ providedIn: 'root' })
export class ChatConnectionStateService {
  private _connectionState: BehaviorSubject<State> = new BehaviorSubject('disconnected');

  public setConnectionState(state: State) {
    debug('set state to', state);
    this._connectionState.next(state);
  }

  public observeOnlineState(): Observable<boolean> {
    return this._connectionState.pipe(map((state: State) => state === 'connected'));
  }

  public get isOnline() {
    return this._connectionState.getValue() === 'connected';
  }
}
