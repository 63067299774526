import { maxBy, minBy } from 'lodash';
import makeDebug from 'src/makeDebug';

import { ChatMessage } from '../data/db-schema';

const debug = makeDebug('services:chat:utils');

export type FetchFunction = (channelSid: string, anchor: number, count: number) => Promise<void>;

export const fetchMissingMessages = async (messages: ChatMessage[], fetchFunction: FetchFunction) => {
  debug('fetch missing messages', messages);
  const necessaryFetches: { channelId: string; index: number }[] = [];
  messages.forEach((message, index, allMessages) => {
    if (index === 0) {
      return;
    }
    const previousMessage = allMessages[index - 1];
    const hasMessageCorrectIndex = previousMessage.index + 1 === message.index;
    if (!hasMessageCorrectIndex) {
      necessaryFetches.push({
        channelId: message.channelSid,
        index: previousMessage.index + 1,
      });
    }
  });
  if (messages.length === 1) {
    necessaryFetches.push({
      channelId: messages[0].channelSid,
      index: messages[0].index,
    });
  }
  debug('found necessary fetched', necessaryFetches);
  const min = minBy(necessaryFetches, 'index');
  const max = maxBy(necessaryFetches, 'index');
  if (max && min) {
    if (max === min) {
      return fetchFunction(max.channelId, max.index, 20);
    }
    const anchor = max.index + 1;
    const count = anchor - min.index;
    debug('fetch', { channelId: max.channelId, anchor, count });
    await fetchFunction(max.channelId, anchor, count);
  }
};
