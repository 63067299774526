import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Socket, io } from 'socket.io-client';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class SignalingService {
  private socket$ = new BehaviorSubject<Socket>(null);
  private offer$ = new BehaviorSubject(null);
  private caller$ = new BehaviorSubject<string>(null);
  private incomingCall$ = new BehaviorSubject(false);
  private answeredOffer$ = new BehaviorSubject(null);
  private callEnded$ = new BehaviorSubject(false);
  private iceCandidate$ = new ReplaySubject();

  constructor(
    private dashboardService: DashboardService,
    private oauthService: OAuthService,
    private authService: AuthService
  ) {}

  public get offer() {
    return this.offer$.asObservable();
  }

  public get caller() {
    return this.caller$.asObservable();
  }

  public get incomingCall() {
    return this.incomingCall$.asObservable();
  }

  public get answeredOffer() {
    return this.answeredOffer$.asObservable();
  }

  public get iceCandidate() {
    return this.iceCandidate$.asObservable();
  }

  public get callEnded() {
    return this.callEnded$.asObservable();
  }

  async connect(): Promise<void> {
    if (this.oauthService.hasValidAccessToken() === false) {
      throw new Error('not authenticated');
    }
    const patientData = await this.dashboardService.getData().toPromise();

    this.socket$.next(
      io(environment.signalingServer, {
        auth: {
          patientId: patientData.patient._id,
          token: this.oauthService.getAccessToken(),
          tenantId: environment.tenantId,
          isPatientApp: true,
        },
        upgrade: false,
        transports: ['websocket'],
      })
    );

    this.socket$.value.on('offer', async offer => {
      if (!this.authService.authentication.account?.legalApproval) {
        return;
      }
      console.log('offer', offer);
      this.callEnded$.next(false);
      this.offer$.next(offer.offer);
      this.caller$.next(offer.caller);
      this.incomingCall$.next(true);
    });

    this.socket$.value.on('ice-candidate', async candidate => {
      console.log('ice-candidate', candidate);
      this.iceCandidate$.next(candidate);
    });

    this.socket$.value.on('endCall', async callEnded => {
      console.log('callEnded', callEnded);
      this.incomingCall$.next(false);
      this.callEnded$.next(callEnded);
    });
  }

  public sendAnswer(answer) {
    console.log('sendAnswer', answer);
    this.socket$.value.emit('answer', answer);
  }

  public sendCandidate(candidate) {
    this.socket$.value.emit('ice-candidate', candidate);
  }

  public setIncomingCall(incomingCall: boolean) {
    this.incomingCall$.next(incomingCall);
  }

  public setAnsweredCall(offer) {
    this.answeredOffer$.next(offer);
  }

  public sendEndCall() {
    this.socket$.value.emit('endCall');
  }

  public clearIceCandidate() {
    this.iceCandidate$ = new ReplaySubject();
  }
}
