import { inject, InjectionToken } from '@angular/core';
import pouchdbDebug from 'pouchdb-debug';
import { addRxPlugin, createRxDatabase, RxDatabase } from 'rxdb';
import { takeWhile } from 'rxjs/operators';
import makeDebug from 'src/makeDebug';
import { AuthService } from '../../auth.service';
import {
  channelsMigrationStrategies,
  channelsSchema,
  chatChannelConsumptionSchema,
  ChatCollections,
  membersSchema,
  messagesMigrationStrategies,
  messagesSchema,
} from './db-schema';

const debug = makeDebug('services:chat:db');

export const RX_DB_SERVICE_TOKEN = new InjectionToken<Promise<RxDatabase<ChatCollections>>>(
  'Manually constructed MyService',
  {
    providedIn: 'root',
    factory: () => _initDb(inject(AuthService)),
  }
);

export const _initDb = async (authService: AuthService) => {
  addRxPlugin(require('pouchdb-adapter-idb'));
  addRxPlugin(pouchdbDebug);
  addRxPlugin(require('rxdb/plugins/watch-for-changes'));

  debug('init db');
  const pouchSettings: any = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    revs_limit: 1,
  };

  // migration - see rx-db-migration.spec.ts (to migrate schema)
  // https://github.com/pubkey/rxdb/blob/master/docs-src/data-migration.md
  // Use for dev
  // await RxDB.removeDatabase('chatdb', 'idb');

  const db = await createRxDatabase<ChatCollections>({
    name: await createDbName(authService),
    adapter: 'idb',
    ignoreDuplicate: true,
    pouchSettings,
  });
  await db.addCollections({
    channels: {
      schema: channelsSchema,
      migrationStrategies: channelsMigrationStrategies,
    },
    consumptions: {
      schema: chatChannelConsumptionSchema,
    },
    messages: {
      schema: messagesSchema,
      migrationStrategies: messagesMigrationStrategies,
    },
    members: {
      schema: membersSchema,
    },
  });
  debug('db init completed');
  // setup watch for changes when writing directly with .pouch
  db.messages.watchForChanges();
  return db;
};

export const createDbName = async (authService: AuthService) =>
  new Promise<string>(resolve => {
    authService.authenticatedEventPublisher.pipe(takeWhile(authEventData => !authEventData.isAuthenticated)).subscribe({
      complete: () => {
        const account = authService.authentication.account;
        // TODO: Branch name mit reinbringen?!
        const dbName = `${account.firstName[0]}${account.lastName[0]}${account._id.slice(-4)}`;

        resolve(dbName.toLowerCase());
      },
    });
  });
