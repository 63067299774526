import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultOAuthInterceptor, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

import { BaseUrlInterceptor } from './interceptor/base-url.interceptor';
import { ParamsTenantInterceptor } from './interceptor/params-tenant.interceptor';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          'https://patient-app-dev.k8s.api.halloalberta.de',
          'https://patient-app-staging.k8s.api.halloalberta.de',
          'https://patient-app.k8s.api.halloalberta.de',
        ],
        sendAccessToken: true,
      },
    }),
  ],
  providers: [
    { provide: OAuthStorage, useValue: localStorage },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultOAuthInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ParamsTenantInterceptor, multi: true },
  ],
})
export class CoreModule {}
