import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, NavigationStart, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private _authService: AuthService) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this._authService.init;

    if (this._authService.authentication.isAuth) {
      return true;
    }

    this._authService.redirectUrl = state.url;

    /*
    This is not a perfect solution, but the auth-libraries seem have problems if the nonce changes
    during a password change.
    Additionally they seem to manipulate/encode the original URL-GET-parameters in an unusual/invalid way:
    ...&state=XXXXXXXXXXXXXX;%252F&userState=Authenticated&passwordChanged=true becomes
    ...&state=XXXXXXXXXXXXXX;%252F%26passwordChanged%3Dtrue&userState=Authenticated...
    This way the passwordChanged-Parameter gets part of the state and consequently lost.
    */
    if (state.url.indexOf('passwordChanged=true') > 0 && state.url.indexOf('state=') > 0) {
      this._authService.initLoginFlow();
      this._authService.logout();
    } else {
      this.router.navigate(['/welcome']);
    }

    return false;
  }
}
