import { Inject, Injectable, NgZone } from '@angular/core';
import { Deferred } from 'src/app/common/deferred/deferred';
import makeDebug from 'src/makeDebug';
import {
  Client as TwilioClient,
  Conversation,
  Participant,
  Message,
  ConversationUpdateReason,
} from '@twilio/conversations';

import { AuthService } from '../../auth.service';
import { ChatConnectionStateService } from '../chat-connection-state.service';
import { ChatEventService } from '../chat-event.service';
import { TwilioToChatDtoHelpersService } from './twilio-to-chat-dto-helpers';
import { TWILIO_TOKEN } from './twilio.factory';

const debug = makeDebug('services:chat:twilioSync');

@Injectable({ providedIn: 'root' })
export class TwilioChatEventSourceService {
  private _ready = new Deferred<void>();

  public get ready(): Promise<void> {
    return this._ready.promise;
  }

  constructor(
    @Inject(TWILIO_TOKEN) private readonly _twilioChatClient: Promise<TwilioClient>,
    private readonly _twilioToChatDtoHelpers: TwilioToChatDtoHelpersService,
    private readonly chatEventService: ChatEventService,
    private readonly chatConnectionStateService: ChatConnectionStateService,
    private readonly _authService: AuthService,
    private readonly _ngZone: NgZone
  ) {
    this.initialize();
  }

  private initialize() {
    debug('init');

    this._ngZone.runOutsideAngular(() =>
      setTimeout(async () => {
        await this.setupChannelEvents();
        await this.setupMessageEvents();
        await this.setupTokenEvents();
        await this.setupConnectionStateEvents();

        this._ready.resolve();
      })
    );
  }

  private async setupChannelEvents() {
    const twilioClient = await this._twilioChatClient;
    debug('setup channel events');
    twilioClient.on('conversationAdded', conversation => this.addConversation(conversation));
    twilioClient.on('conversationUpdated', data => this.updateConversation(data.conversation, data.updateReasons));
    twilioClient.on('conversationRemoved', conversation => this.removeConversation(conversation));
  }

  private async setupMessageEvents() {
    const twilioClient = await this._twilioChatClient;
    debug('setup message events');
    twilioClient.on('messageAdded', message => this.addMessage(message));
    twilioClient.on('messageRemoved', message => this.removeMessage(message));
    twilioClient.on('participantJoined', participant => this.addParticipantToConversation(participant));
    twilioClient.on('participantLeft', participant => this.removeParticipantFromConversation(participant));
  }

  private async setupTokenEvents() {
    const twilioClient = await this._twilioChatClient;
    debug('setup token events');
    twilioClient.on('tokenAboutToExpire', () => this.reactToTokenAboutToExpire());
    twilioClient.on('tokenExpired', () => this.reactToTokenExpired());
  }
  private async addConversation(conversation: Conversation) {
    if (conversation.uniqueName !== this._authService.authentication.account.hash) {
      return;
    }

    debug('twilio: conversation added', conversation);
    const chatChannel = this._twilioToChatDtoHelpers.convertToChatChannel(conversation);
    await this.chatEventService.addChatChannel(chatChannel);

    const members = await conversation.getParticipants();
    const chatMembers = members.map(member => this._twilioToChatDtoHelpers.convertToChatMember(member));
    return this.chatEventService.updateChannelMembers(conversation.sid, chatMembers);
  }

  private async updateConversation(conversation: Conversation, updateReasons: ConversationUpdateReason[]) {
    if (conversation.uniqueName !== this._authService.authentication.account.hash) {
      return;
    }

    debug('twilio: channel updated', conversation, updateReasons);
    const chatChannel = this._twilioToChatDtoHelpers.convertToChatChannel(conversation);
    return await this.chatEventService.updateChatChannel(chatChannel);
  }

  private async removeConversation(conversation: Conversation) {
    debug('twilio: channel removed', conversation);
    return this.chatEventService.removeChatChannelById(conversation.sid);
  }

  private async addParticipantToConversation(participant: Participant) {
    debug('twilio: member joined', participant);
    const channelMember = this._twilioToChatDtoHelpers.convertToChatMember(participant);
    return this.chatEventService.addMemberToChannel(channelMember);
  }

  private async removeParticipantFromConversation(participant: Participant) {
    debug('twilio: member left', participant);
    return this.chatEventService.removeMemberFromChannel(participant.conversation.sid, participant.sid);
  }

  private async addMessage(message: Message) {
    debug('twilio: message added', message);
    const chatMessage = await this._twilioToChatDtoHelpers.convertToChatMessage(message);
    return this.chatEventService.addMessage(chatMessage);
  }

  private async removeMessage(message: Message) {
    debug('twilio: message removed', message);
    return this.chatEventService.removeMessageById(message.sid);
  }

  private reactToTokenAboutToExpire() {
    debug('twilio: token about to expire');
    this.chatEventService.reactToTokenAboutToExpire();
  }

  private reactToTokenExpired() {
    debug('twilio: token expired');
    this.chatEventService.reactToTokenExpired();
  }

  private async setupConnectionStateEvents() {
    const twilioClient = await this._twilioChatClient;
    twilioClient.on('connectionStateChanged', state => this.handleConnectionStateChanged(state));
    if (twilioClient.connectionState === 'connected') {
      this.chatConnectionStateService.setConnectionState('connected');
    }
  }

  private handleConnectionStateChanged(connectionState) {
    debug('connection state changed', connectionState);
    this.chatConnectionStateService.setConnectionState(connectionState);
  }
}
