import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IAuthEventData } from 'src/app/common/contracts/authentication/auth-event-data';
import makeDebug from '../../../../makeDebug';
import { AuthService } from '../auth.service';
import { ChatTokenService } from '../chat-token.service';

const debug = makeDebug('services:chat:user');

@Injectable({ providedIn: 'root' })
export class ChatUserService {
  get localUserChatToken$(): Observable<string> {
    return this._localUserChatToken$.asObservable();
  }

  private _localUserIdentity$ = new ReplaySubject<string>();
  private _localUserChatToken$ = new ReplaySubject<string>();

  constructor(private readonly _authService: AuthService, private _chatTokenService: ChatTokenService) {
    this._authService.authenticatedEventPublisher.subscribe((authEvent: IAuthEventData) => {
      if (authEvent.isAuthenticated && authEvent.authOnline) {
        this._updateAccountChatInformation().catch(err => window.logger.error('_updateAccountChatInformation', err));
      }
    });
  }

  public getUserDisplayName() {
    return `${this._authService.authentication.account.firstName} ${this._authService.authentication.account.lastName}`;
  }

  public getUserIdentity(): Promise<string> {
    debug('get user chat identity');
    return this._localUserIdentity$.pipe(take(1)).toPromise();
  }

  public async refreshToken(): Promise<string> {
    await this._authService.init;

    debug('refreshing token...');
    const chatToken = await this.getChatToken();
    if (!chatToken) {
      throw Error('refreshing failed');
    }
    this._localUserChatToken$.next(chatToken);
    return chatToken;
  }

  private async _updateAccountChatInformation() {
    if (!this._authService.authentication.account) {
      return;
    }

    const chatToken = await this.getChatToken();
    const { _id } = this._authService.authentication.account;
    debug('update account information', { chatToken });

    if (chatToken) {
      this._localUserChatToken$.next(chatToken);
    }

    this._localUserIdentity$.next(_id);
  }

  private async getChatToken(): Promise<string> {
    await this._authService.init;

    const promise = new Promise<string>(resolve => {
      this._chatTokenService
        .getChatToken(this._authService.authentication.account._id)
        .pipe(map(chatToken => chatToken.token))
        .subscribe({
          next: chatToken => resolve(chatToken),
          error: error => {
            console.error(JSON.stringify(error));
            resolve(null);
          },
        });
    });

    return promise;
  }
}
