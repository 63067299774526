import { Platform } from '@angular/cdk/platform';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChatTokenService {
  constructor(private http: HttpClient, private _platform: Platform) {}

  getChatToken(userId: string): Observable<{ token: string }> {
    return this.http.post<{ token: string }>('/chat', {
      userId,
      pushChannel: this._platform.IOS ? 'apn' : 'fcm',
    });
  }
}
