import { Injectable } from '@angular/core';
import amplitude from 'amplitude-js';
import makeDebug from 'debug';
import { cloneDeep } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';

import { DocumentType } from '../documents/documents.service';
import { ErpOrderStatus } from '../shared/models/contracts/erpOrder-status';
import { ChatChannelDetail } from '../shared/services/chat/model/chat.model';

const debug = makeDebug('services:tracker');
/**
 * Action event state for every cancelable user interaction
 */
export enum ActionEvent {
  Start = 'Start',
  Done = 'Done',
  Cancel = 'Cancel',
}

/**
 * Share event type for every share action that somehow transfers an object
 * from the user to somewhere else
 */
export enum ShareEventType {
  Chat = 'Chat',
  Fax = 'Fax',
  Email = 'Email',
  Download = 'Download',
  Print = 'Print',
}

export enum ActivityType {
  Unknown,
  Patient,
  PatientList,
  NursingHome,
}

export enum SimpleDocumentType {
  Invoice = 'Invoice',
  Other = 'Other',
}

export enum StatusType {
  Start = 'Start',
  Done = 'Done',
  Failed = 'Failed',
}
export class ScheduledLogEvent {
  private timeoutHandler: NodeJS.Timeout;

  constructor(timeoutHandler: NodeJS.Timeout) {
    this.timeoutHandler = timeoutHandler;
  }

  public cancel() {
    global.clearTimeout(this.timeoutHandler);
  }
}

@Injectable({ providedIn: 'root' })
export class TrackerService {
  private _isTrackingEnabled = environment.amplitude?.enabled || false;
  private readonly _appType: string;
  private _tracker: amplitude.AmplitudeClient;
  private readonly _urlMatcher = new RegExp(/\/(?<path>[^\?\/&\.]+(?<file>\.[a-z0-9]+)?)?/g);

  constructor(private _deviceService: DeviceDetectorService) {
    if (this._isTrackingEnabled) {
      this._tracker = amplitude.getInstance();
      /* TODO: Options
      Suggest using batchEvents to save bandwidth and cpu time
      */

      this._tracker.init(environment.amplitude.apiKey);
      this._tracker.setUserProperties({
        deviceFormfactor: this._deviceService.deviceType,
        operatingSystem: this._deviceService.os,
        orientation: this._deviceService.orientation,
      });

      this._appType = this._isWebOrPWA();
    }
  }

  private _isWebOrPWA() {
    let displayMode = 'browser';
    const mqStandAlone = '(display-mode: standalone)';
    if (window.navigator['standalne'] || window.matchMedia(mqStandAlone).matches) {
      displayMode = 'pwa';
    }

    return displayMode;
  }

  public identify(userId: string, email: string, tenantId: string) {
    try {
      if (this._tracker) {
        this._tracker.setUserId(userId);
        this._tracker.setUserProperties({
          email,
          tenantId,
        });
      }
    } catch (e) {
      debug('Failed to initialize tracking', e);
    }
  }

  public setVersion(release: string, version: string) {
    debug('set release and version', release, version);
    this._tracker.setVersionName(release + '#' + version);
  }

  public clearIdentify() {
    debug('clear identify');
    this._tracker.clearUserProperties();
  }

  public setTrackingEnabled(enabled: boolean) {
    debug('set tracking status', enabled);
    this._isTrackingEnabled = enabled;
  }

  /**
   * Default log event for Amplitude Service
   */
  private logEvent(name: string, params: any = null) {
    if (!this._isTrackingEnabled) {
      return;
    }

    let populatedParams = cloneDeep(params);
    if (!populatedParams) {
      populatedParams = {};
    }
    populatedParams.appType = this._appType;

    global.setTimeout(() => {
      try {
        debug('log event', name, populatedParams);
        this._tracker.logEvent(name, populatedParams);
      } catch (err) {
        window.logger.error('user tracking error', err);
      }
    });
  }

  /**
   * Schedule a log event for Amplitude Service. This log event is scheduled to log after
   * a certain delay, if no canceled explicitely
   */
  private scheduleLogEvent(name: string, params: any = null, delay: number = 1000): ScheduledLogEvent {
    if (!this._isTrackingEnabled) {
      return;
    }

    const timeoutHandler = global.setTimeout(() => {
      this.logEvent(name, params);
    }, delay);

    return new ScheduledLogEvent(timeoutHandler);
  }

  /* NAVIGATION */
  public trackNavigateTo(url: string): void {
    if (url) {
      try {
        const page = [];
        let matches = null;

        // RegExp are stateful, reset last state
        this._urlMatcher.lastIndex = 0;

        do {
          matches = this._urlMatcher.exec(url);

          if (matches) {
            if (matches.groups.file) {
              // file openings will be tracked otherwise
              return;
            }

            page.push(matches.groups.path || 'dashboard');
          }
        } while (matches);

        if (page.length) {
          console.log(`navigate to ${page.join(':')}`);
          this.logEvent(`page:${page.join(':')}:show`);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  /* NOTIFICATIONS */

  public trackNotificationsOpen(actionEvent: ActionEvent) {
    this.logEvent('notifications:open', { actionEvent });
  }

  public trackNotificationsDelete(destination: string) {
    this.logEvent('notifications:action:delete', { destination });
  }

  public trackNotificationsNavigateTo(destination: string) {
    this.logEvent('notifications:action:navigate-to', { destination });
  }

  /* AUTH */
  public trackLogin() {
    this.logEvent('auth:login');
  }

  public trackLogout() {
    this.logEvent('auth:logout');
  }

  public trackLoginWrongPassword() {
    this.logEvent('auth:wrong-password');
  }

  public trackRegistration() {
    this.logEvent('auth:register', {});
  }

  public trackRegistrationFailed() {
    this.logEvent('auth:registeration:failed', {});
  }

  /* ATTACHMENT */
  public trackDocumentShow(documentType: SimpleDocumentType) {
    this.logEvent('document:show', { documentType });
  }

  public trackDocumentDownload() {
    this.logEvent('document:download');
  }

  public trackDocumentUpload(actionEvent: ActionEvent, documentType: DocumentType) {
    this.scheduleLogEvent('document:upload', { actionEvent, documentType });
  }

  /* CONTACT-CARD */
  public trackContactCallbackServiceSend(status: StatusType, subject: string, messageLength: number) {
    this.logEvent('contact:callback-service:send', { status, subject, messageLength });
  }

  public trackContactContactDataTriggerPhone() {
    this.logEvent('contact:contact-data:trigger:phone');
  }

  public trackContactContactDataTriggerEmail() {
    this.logEvent('contact:contact-data:trigger:email');
  }

  public trackContactContactDataTriggerFaq() {
    this.logEvent('contact:contact-data:trigger:faq');
  }

  /* CHAT */
  public trackChatShowChannels(numberOfChannels: number) {
    this.logEvent('chat:show-channels', { numberOfChannels });
  }
  public trackChatSendMessage(channel: ChatChannelDetail) {
    this.logEvent('chat:sendMessage', {
      channelId: channel.sid,
      isActive: channel.isActive,
      isOpen: channel.isOpen,
      clientType: 'patient',
    });
  }
  public trackChatChannelReused(channel: ChatChannelDetail) {
    this.logEvent('chat:channelReopened', {
      channelId: channel.sid,
      clientType: 'patient',
    });
  }

  /* PATIENT CONTACTS */
  public trackPatientContactsOpen(): ScheduledLogEvent {
    return this.scheduleLogEvent('patient:contacts:open');
  }

  public trackPatientContactDetail() {
    this.logEvent('patient:contact:detail');
  }

  public trackPatientContactEdit(actionEvent: ActionEvent) {
    this.logEvent('patient:contact:action:edit', { actionEvent });
  }

  /* ORDERS */
  public trackOrderShowMore(status: ErpOrderStatus, outboundDate: Date) {
    this.logEvent('order:show-more:open', { status, outboundDate });
  }

  public trackOrderFollowTrackingLink(status: ErpOrderStatus, outboundDate: Date) {
    this.logEvent('order:follow-tracking-link', { status, outboundDate });
  }

  /* PWA Installation */
  public trackInstallPwaFromDashboard() {
    this.logEvent('pwa:install-from:dashboard');
  }
}
