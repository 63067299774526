import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'itl-flat-button',
  templateUrl: './flat-button.component.html',
  styleUrls: ['./flat-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlatButtonComponent {
  @Input() disabled = false;
  @Input() ariaLabel = 'button';
  @Input() singleButton = false;
}
