import { Injectable } from '@angular/core';

import { LogLevel } from '../contracts/logging/log-level';
import { ILogger } from '../contracts/logging/logger';

@Injectable({ providedIn: 'root' })
export class Logger implements ILogger {
  private static instance: ILogger;
  private _logLevel: LogLevel;

  get logLevel(): LogLevel {
    return this._logLevel;
  }

  set logLevel(logLevel: LogLevel) {
    this._logLevel = logLevel;
  }

  constructor() {
    this._logLevel = LogLevel.verbose;

    Logger.setInstance(this);
  }

  private static setInstance(logger: ILogger) {
    Logger.instance = logger;
  }

  static log(message: string, level: LogLevel = LogLevel.verbose) {
    return Logger.instance.log(message, level);
  }

  static error(message: string, error: Error) {
    return Logger.instance.error(message, error);
  }

  static info(message: string, level: LogLevel = LogLevel.info) {
    return Logger.instance.info(message, level);
  }

  log(message: string, level: LogLevel = LogLevel.verbose) {
    console.log(message);
  }

  error(message: string, error: Error, level: LogLevel = LogLevel.fatal) {
    console.error(`Error occured / message: ${message} - error: ${error.message}`);
  }

  info(message: string, level: LogLevel = LogLevel.info) {
    console.log(message);
  }
}
