import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './shared/guards/auth.guard';
import { NoAuthGuard } from './shared/guards/no-auth.guard';

const routes: Routes = [
  {
    path: 'welcome',
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./welcome/welcome.module').then(m => m.WelcomeModule),
  },
  {
    path: 'register',
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule),
  },
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'orders',
    canActivate: [AuthGuard],
    loadChildren: () => import('./order/order-list.module').then(m => m.OrderListModule),
  },
  {
    path: 'documents',
    canActivate: [AuthGuard],
    loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule),
  },
  {
    path: 'contact',
    canActivate: [AuthGuard],
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
  },
  {
    path: '**',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
