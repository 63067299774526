import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'itl-incoming-call',
  templateUrl: './incoming-call.component.html',
  styleUrls: ['./incoming-call.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncomingCallComponent implements OnInit {
  @Output() public accept = new EventEmitter<void>();
  @Input() public offer;
  @Input() public caller;

  constructor() {}

  ngOnInit(): void {}

  public handleAccept() {
    this.accept.emit(this.offer);
  }
}
