<nav [ngClass]="{'safe-space': bottomSafeSpace === true}" *ngIf="!isHidden">
  <a class="tab-link" *ngFor="let tab of this.tabs" [routerLink]="tab.link">
    <div class="tab-button" [ngClass]="{ active: currentUrl === tab.link }">
      <mat-icon class="tab-icon" svgIcon="{{ tab.icon }}"></mat-icon>
      <span class="tab-label" [ngClass]="{ active: currentUrl === tab.link }">{{ tab.label }}</span>
      <div class="notification" *ngIf="currentUrl !== tab.link && unreadMessages && tab.link === '/chat'">
        {{ unreadMessages }}
      </div>
    </div>
  </a>
</nav>
