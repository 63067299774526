import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'itl-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss'],
})
export class PromptComponent implements OnInit {
  public promptEvent = this.data.promptEvent;
  public readonly pweMoreInfoLink = environment.pwaMoreInfoLink;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mobileType: 'ios' | 'android'; promptEvent?: any },
    private dialogRef: MatDialogRef<PromptComponent>
  ) {}
  ngOnInit() {
    this.dialogRef.updatePosition({ top: `10px` });
    this.dialogRef.updateSize('90%');
  }
  public installPwa(): void {
    this.data.promptEvent.prompt();
    this.close();
  }

  public close() {
    this.dialogRef.close();
  }
}
