import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LastLoginService {
  constructor(private http: HttpClient) {}

  public updateLastLogin(userId: string) {
    this.http
      .patch(`/pa_users/${userId}`, {
        lastLoginDate: new Date(),
      })
      .toPromise(); // it's important to do anything with the request, otherwise it will not execute
  }
}
