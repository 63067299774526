import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NetworkStateService {
  private readonly _isOnlineChanged = new BehaviorSubject(true);

  constructor() {}

  init() {
    window.addEventListener('online', this._handleConnectionChange.bind(this));
    window.addEventListener('offline', this._handleConnectionChange.bind(this));
  }

  public get isOnline() {
    return this._isOnlineChanged.getValue();
  }

  public observeOnlineState(): Observable<boolean> {
    return this._isOnlineChanged;
  }

  private async _handleConnectionChange() {
    const isOnline = navigator.onLine && (await this._isReachable(window.location.origin));

    this._isOnlineChanged.next(isOnline);
  }

  private async _isReachable(url: string) {
    try {
      const httpResult = await fetch(url, { method: 'HEAD', mode: 'no-cors' });

      return httpResult && (httpResult.ok || httpResult.type === 'opaque');
    } catch (error) {
      return false;
    }
  }
}
