import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from 'src/app/shared/material/material.module';
import { FlatButtonModule } from '../flat-button/flat-button.module';
import { IncomingCallComponent } from './incoming-call.component';

@NgModule({
  imports: [CommonModule, MaterialModule, FlatButtonModule],
  declarations: [IncomingCallComponent],
  exports: [IncomingCallComponent],
})
export class IncomingCallModule {}
