import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { AppInitService } from './app-init.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './common/core.module';
import { NetworkWarningComponent } from './network-warning/network-warning.component';
import { PromptComponent } from './prompt/prompt.component';
import { IncomingCallModule } from './shared/components/common/incoming-call/incoming-call.module';
import { NavBarModule } from './shared/components/common/nav-bar/nav-bar.module';
import { PageWrapperModule } from './shared/components/page-wrapper/page-wrapper.module';
import { MaterialModule } from './shared/material/material.module';

export const initApp = (appInitService: AppInitService) => () => appInitService.init();

@NgModule({
  declarations: [AppComponent, PromptComponent, NetworkWarningComponent],
  imports: [
    AppRoutingModule,
    CoreModule,
    BrowserModule,
    NavBarModule,
    IncomingCallModule,
    PageWrapperModule,
    MaterialModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MatSnackBarModule,
  ],
  providers: [AppInitService, { provide: APP_INITIALIZER, useFactory: initApp, deps: [AppInitService], multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule {}
