import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = request.url;

    if (url.startsWith('http') === true) {
      return next.handle(request);
    }

    if (url.startsWith('/') === true) {
      url = url.substring(1);
    }
    const apiReq = request.clone({
      url: `${environment.baseUrl}/${url}`,
    });

    return next.handle(apiReq);
  }
}
