import { Injectable } from '@angular/core';
import makeDebug from 'debug';
import { isEqual } from 'lodash';

import { ChatUserService } from './chat-user.service';
import { ChatDbService } from './data/chat-db.service';
import { ChatChannel, ChatMember, ChatMessage } from './data/db-schema';

const debug = makeDebug('services:chat:event-service');

@Injectable({
  providedIn: 'root',
})
export class ChatEventService {
  constructor(private readonly _chatDb: ChatDbService, private readonly _chatUserService: ChatUserService) {}

  public async addChatChannel(chatChannel: ChatChannel) {
    debug('added channel', chatChannel);
    const relevantFields = [
      'dateCreated',
      'dateUpdated',
      'friendlyName',
      'lastConsumedMessageIndex',
      'lastMessageIndex',
      'lastMessageTimestamp',
      'sid',
      'unconsumedMessagesCount',
    ];
    const channel = await this._chatDb.getChannel(chatChannel.sid);
    const hasChanges =
      channel && relevantFields.some((field: string) => isEqual(channel[field], chatChannel[field]) === false);
    if (!channel || hasChanges) {
      debug('channel changed. Upsert!', { channel, chatChannel });
      return this._chatDb.upsertChannel(chatChannel);
    }
  }

  public async updateChatChannel(chatChannel: ChatChannel) {
    debug('updated channel', chatChannel);
    return this._chatDb.upsertChannel(chatChannel);
  }

  public async removeMessageById(sid: string) {
    debug('remove message by id', sid);
    return this._chatDb.removeMessage(sid);
  }

  public async removeChatChannelById(id: string) {
    debug('remove chat by id');
    return this._chatDb.removeChannel(id);
  }

  public async addMessage(chatMessage: ChatMessage) {
    debug('add message', chatMessage);
    const localUserIdentity = await this._chatUserService.getUserIdentity();
    const isLocalUser = chatMessage.memberIdentity === localUserIdentity;
    chatMessage.isLocal = isLocalUser;
    return this._chatDb.upsertMessage(chatMessage);
  }

  public async removeMemberFromChannel(channelId: any, memberId: string) {
    debug('remove member from channel', channelId, memberId);
    return this._chatDb.removeChannelMember(channelId, memberId);
  }

  public async addMemberToChannel(channelMember: ChatMember) {
    debug('add member to channel', channelMember);
    const localUserIdentity = await this._chatUserService.getUserIdentity();
    const isLocalUser = channelMember.identity === localUserIdentity;
    channelMember.isLocal = isLocalUser;
    return this._chatDb.upsertChannelMember(channelMember);
  }

  public async updateChannelMembers(channelId: string, chatMembers: ChatMember[]) {
    debug('update channel members');
    const localUserIdentity = await this._chatUserService.getUserIdentity();

    for (const member of chatMembers) {
      member.isLocal = member.identity === localUserIdentity;
    }
    return this._chatDb.bulkUpdateChannelMembers(channelId, chatMembers);
  }

  public async reactToTokenExpired() {
    try {
      await this._chatUserService.refreshToken();
    } catch (e) {
      setTimeout(() => this.reactToTokenExpired(), 5000);
    }
  }

  public async reactToTokenAboutToExpire() {
    try {
      await this._chatUserService.refreshToken();
    } catch (e) {
      debug.log('refreshing Token failed');
    }
  }
}
