import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ParamsTenantInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      request.url.includes('auth.halloalberta.de') ||
      request.url.includes('assets/icons') ||
      request.url.includes('.svg')
    ) {
      return next.handle(request);
    }

    request = request.clone({ params: request.params.set('tenantId', environment.tenantId) });

    return next.handle(request);
  }
}
