import { Injectable } from '@angular/core';
import { takeWhile } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { ChatService } from './chat/chat.service';

@Injectable({ providedIn: 'root' })
export class ChannelService {
  constructor(private readonly _chatService: ChatService, private readonly _authService: AuthService) {}

  public async init(): Promise<void> {
    return new Promise<void>(resolve => {
      this._authService.authenticatedEventPublisher.pipe(takeWhile(authEvent => !authEvent.isAuthenticated)).subscribe({
        complete: async () => {
          if (this._authService.authentication.account.channelSid) {
            return;
          }

          this._chatService.createChat().subscribe({
            next: channelSid => {
              this._authService.authentication.account.channelSid = channelSid;
            },
            complete: () => resolve(),
            error: error => {
              window.logger.error('Channel creation failed.', error);
              resolve();
            },
          });
        },
      });
    });
  }
}
