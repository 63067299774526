import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthConfig, OAuthModuleConfig, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject } from 'rxjs';
import { IAuthEventData } from 'src/app/common/contracts/authentication/auth-event-data';
import { Deferred } from 'src/app/common/deferred/deferred';
import { LastLoginService } from 'src/app/services/last-login.service';
import { TrackerService } from 'src/app/services/tracker.service';
import { environment } from 'src/environments/environment';
import makeDebug from 'src/makeDebug';

import { IUser } from '../models/contracts/user';

const debug = makeDebug('services:auth:service');

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _currentUser = new BehaviorSubject<IUser>(null);
  public currentUser = this._currentUser.asObservable();

  private _init = new Deferred<void>();
  public init = this._init.promise;
  private _authenticationEventSubject = new BehaviorSubject<IAuthEventData>({
    isAuthenticated: false,
  });
  public authenticatedEventPublisher = this._authenticationEventSubject.asObservable();
  public redirectUrl: string;

  public authentication = {
    isAuth: false,
    account: undefined,
  };

  private get hasValidAccessToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  constructor(
    private oauthService: OAuthService,
    private http: HttpClient,
    moduleConfig: OAuthModuleConfig,
    private _trackerService: TrackerService,
    private _lastLoginService: LastLoginService
  ) {
    moduleConfig.resourceServer.allowedUrls.push(environment.baseUrl);
    this.configure(environment.oauthConfig);
  }

  public initLoginFlow() {
    this.oauthService.initLoginFlow(this.redirectUrl);

    this._trackerService.trackLogin();
  }

  public logout() {
    this.authentication.account = null;
    this.authentication.isAuth = false;
    this.oauthService.logOut({ tenantId: '926034ae-dc97-4e82-8798-2bf248e594e2' });

    this._trackerService.trackLogout();
  }

  private async configure(oauthConfig: AuthConfig) {
    if (!this.oauthService.tokenEndpoint) {
      this.oauthService.configure(oauthConfig);

      await this.oauthService.loadDiscoveryDocument();

      this.oauthService.setupAutomaticSilentRefresh();
    }
    await this.authenticate();
  }

  private async authenticate() {
    try {
      await this.oauthService.tryLogin();
    } catch (e) {
      debug('Failed to authenticate', e);
    }

    if (this.hasValidAccessToken) {
      try {
        const user = await this.loadCurrentUser();
        this._currentUser.next(user);
        this.authentication.account = user;
        this._authenticationEventSubject.next({ isAuthenticated: true, authOnline: true });
        this.authentication.isAuth = true;

        try {
          this._lastLoginService.updateLastLogin(user._id);
          this._trackerService.identify(user._id, user.email, user.tenantId);
        } catch (error) {
          console.error(error);
        }
      } catch (e) {
        debug('Failed to authenticate', e);
        this.logout();
      }
    }
    this._init.resolve();
  }

  private async loadCurrentUser(): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      this.http.get<IUser>('/me').subscribe({
        next: user => {
          if (user.tenantId !== environment.tenantId) {
            reject('Wrong tenantId');
          } else {
            resolve(user);
          }
        },
        error: reason => reject(reason),
      });
    });
  }

  public async toggleLegalApproval(approval: boolean): Promise<void> {
    const data = { legalApproval: approval };
    if (approval) {
      data['legalApprovalDate'] = new Date();
    } else {
      data['legalDisprovalDate'] = new Date();
    }
    const patchedUser = await new Promise<IUser>((resolve, reject) =>
      this.http.patch<IUser>(`/me/${this.authentication.account._id}`, data).subscribe({
        next: user => {
          resolve(user);
        },
        error: reason => reject(reason),
      })
    );
    this._currentUser.next(patchedUser);
    this.authentication.account = patchedUser;
  }
}
