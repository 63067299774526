<div *ngIf="data.mobileType === 'android'" class="prompt">
  <div class="hint">
    Möchten Sie diese Internetseite auf den Start-Bildschirm Ihres mobilen Endgerätes hinzufügen?
  </div>
  <div class="buttons">
    <button mat-button (click)="installPwa()">
      Hinzufügen
    </button>
    <button mat-icon-button (click)="close()">
      Später
    </button>
  </div>
</div>

<div *ngIf="data.mobileType === 'ios'" class="prompt">
  <div class="grid">
    <div class="icons">
      <mat-icon svgIcon="pwa_prompt"></mat-icon>
    </div>
    <div class="hint">
      Möchten Sie diese Internetseite auf den Start-Bildschirm Ihres mobilen Endgerätes hinzufügen?
    </div>
  </div>
  <div class="buttons">
    <a [href]="this.pweMoreInfoLink" target="_blank" rel="noopener noreferrer">
      Mehr erfahren
    </a>
    <button mat-icon-button (click)="close()">
      Später
    </button>
  </div>
</div>