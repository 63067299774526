import { Platform } from '@angular/cdk/platform';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { clamp } from 'lodash';
import { ICoreConfig } from 'src/app/common/config/core-config.token';
import { ConfigService } from 'src/app/services/config.service';
import { TrackerService } from 'src/app/services/tracker.service';
import { ChatMessagesStatusService } from 'src/app/shared/services/chat/chat-messages-status.service';

@Component({
  selector: 'itl-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarComponent implements OnInit {
  @Input() tabs: ICoreConfig['tabs'];

  public get currentUrl(): string {
    const url = this._currentUrl ? this._currentUrl : this.router.routerState.snapshot.url;
    return url.split('?')[0];
  }
  public unreadMessages = 0;
  private _currentUrl: string;
  public bottomSafeSpace = false;

  get isHidden(): boolean {
    return !this.tabs?.some(tab => tab.link.includes(this.currentUrl));
  }

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private _configService: ConfigService,
    private readonly _chatMessagesStatusService: ChatMessagesStatusService,
    private _trackerService: TrackerService,
    public platform: Platform
  ) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this._currentUrl = event.url;
      } else if (event instanceof NavigationEnd) {
        this._currentUrl = event.url;
        this.changeDetectorRef.markForCheck();

        this._trackerService.trackNavigateTo(event.url);
      }
    });
  }

  async ngOnInit(): Promise<void> {
    try {
      const config = await this._configService.getTenantConfig();
      this.tabs = config.tabs;
    } catch (error) {}

    this.checkIfBottomSpaceNeeded();
    this._chatMessagesStatusService.getTotalUnreadMessagesCount().subscribe(unreadMessages => {
      this.unreadMessages = clamp(unreadMessages, 0, 999);
      this.changeDetectorRef.markForCheck();
    });
  }

  private checkIfBottomSpaceNeeded() {
    if (this.platform.IOS && 'standalone' in window.navigator && window.navigator['standalone']) {
      this.bottomSafeSpace = true;
    } else {
      this.bottomSafeSpace = false;
    }
    this.changeDetectorRef.detectChanges();
  }
}
