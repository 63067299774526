<!-- <header class="flex">Patientenapp</header> -->
<itl-page-wrapper>
  <ng-container *ngIf="isOnline">
    <itl-incoming-call *ngIf="(incomingCall | async) && isAuth" [offer]="offer | async" [caller]="caller | async"
      (accept)="acceptIncomingCall($event)"></itl-incoming-call>
    <router-outlet></router-outlet>

    <!-- TODO: fix Login Button -->
    <!-- <button (click)="this.logout()" *ngIf="this.authService.currentUser | async">
      logout
    </button> -->

    <itl-nav-bar></itl-nav-bar>
  </ng-container>

  <itl-network-warning class="offline-indicator" [ngClass]="{ 'show-offline': !isOnline }"></itl-network-warning>
</itl-page-wrapper>
