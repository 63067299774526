import { Injectable } from '@angular/core';

import { Logger } from './common/logging/logger';
import { NetworkStateService } from './services/network-state.service';
import { ChannelService } from './shared/services/channel.service';
import { ChatService } from './shared/services/chat/chat.service';
import { PwaService } from './shared/services/pwa.service';

@Injectable()
export class AppInitService {
  constructor(
    logger: Logger,
    private readonly _pwaService: PwaService,
    private readonly _channelService: ChannelService,
    private _chatService: ChatService,
    private _networkStateService: NetworkStateService
  ) {
    window.logger = logger;
  }

  public init(): void {
    this._pwaService.init();
    this._channelService.init();
    this._networkStateService.init();
  }
}
