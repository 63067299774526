import { HttpClient } from '@angular/common/http';
import { Inject, inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import makeDebug from 'src/makeDebug';

import { Client as TwilioClient } from '@twilio/conversations';
import { ChatMessage } from './data/db-schema';
import { TwilioToChatDtoHelpersService } from './twillio/twilio-to-chat-dto-helpers';
import { TWILIO_TOKEN } from './twillio/twilio.factory';

@Injectable({
  providedIn: 'root',
  // factory needed because with "useClass" it would not inject the twilio token properly
  useFactory: () =>
    new TwilioChatSendService(inject(TWILIO_TOKEN), inject(TwilioToChatDtoHelpersService), inject(HttpClient)),
})
export abstract class ChatSendService {
  public abstract createChat(): Observable<string>;
  public abstract fetchMessagesOfChannel(channelSid: string, anchor: number, count: number): Promise<ChatMessage[]>;
  public abstract sendMessage(channelId: string, messageBody: string, attributes: any): Promise<ChatMessage>;
  public abstract setConsumptionIndex(channelId: string, index: number): Promise<void>;
}

const debug = makeDebug('services:chat:twilio-sendService');

@Injectable({
  providedIn: 'root',
})
export class TwilioChatSendService implements ChatSendService {
  constructor(
    @Inject(TWILIO_TOKEN) private readonly _twilioChatClient: Promise<TwilioClient>,
    private readonly twilioDtoHelper: TwilioToChatDtoHelpersService,
    private readonly _httpClient: HttpClient
  ) {}

  /**
   * Only works in online mode
   */
  public createChat(): Observable<string> {
    return this._httpClient.post<string>('/channel', {});
  }

  public async fetchMessagesOfChannel(channelSid: string, anchor: number, count: number): Promise<ChatMessage[]> {
    debug('fetch messages of channel', { channelSid, anchor, count });
    const client = await this._twilioChatClient;
    const conversation = await client.getConversationBySid(channelSid);
    const messages = await conversation.getMessages(count, anchor);
    const convertedMessages = [];

    for (const message of messages.items) {
      const convertedMessage = await this.twilioDtoHelper.convertToChatMessage(message);
      convertedMessages.push(convertedMessage);
    }
    debug('got messages from twlio', { channelSid, anchor, count, convertedMessages });
    return convertedMessages;
  }

  public async sendMessage(conversationSid: string, bodyText: string, attributes: any): Promise<ChatMessage> {
    debug('send message in queue', bodyText);
    const twilioClient = await this._twilioChatClient;

    let conversation = null;
    try {
      conversation = await twilioClient.getConversationBySid(conversationSid);
    } catch (error) {
      // instead of returning null, twilio rejects the promise
      // resulting in an ignorable exception.
    }
    if (!conversation) {
      throw new Error('conversation not found:' + conversationSid);
    }
    debug('sending message...', bodyText);

    bodyText = this.twilioDtoHelper.enrichMessageWithPushMarkers(conversation, bodyText);

    const result = await conversation.sendMessage(bodyText, attributes);
    if (!Number.isInteger(result)) {
      throw new Error('send-failed-with:' + result);
    }
    const message = await conversation.getMessages(1, result);
    debug('got send result for', bodyText, 'result:', result);
    const chatMessage = await this.twilioDtoHelper.convertToChatMessage(message.items[0]);

    try {
      // needs a cast to any as TS warns "Spread types may only be created from object types"
      await conversation.updateAttributes({ ...(conversation.attributes as any), isOpen: true });
    } catch (error) {
      console.error(JSON.stringify(error));
    }

    return chatMessage;
  }

  public async setConsumptionIndex(channelId: string, index: number): Promise<void> {
    debug('set consumption index', { channelId, index });
    const twilioClient = await this._twilioChatClient;
    const conversation = await twilioClient.getConversationBySid(channelId);
    if (!conversation) {
      throw new Error('channel not found:' + channelId);
    }
    await conversation.advanceLastReadMessageIndex(index);
  }
}
