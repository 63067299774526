import { inject, InjectionToken } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Client as TwilioClient, ClientOptions } from '@twilio/conversations';

import makeDebug from '../../../../../makeDebug';
import { ChatUserService } from '../chat-user.service';

const debug = makeDebug('services:chat:twilio');

export type twilioClientFactory = (token: string, options?: ClientOptions) => Promise<TwilioClient>;

export const TWILIO_TOKEN = new InjectionToken<Promise<TwilioClient>>('Manually constructed Twilio Service', {
  providedIn: 'root',
  factory: () => _initTwilio(inject(ChatUserService)),
});

export const _initTwilio = (_chatUserService: ChatUserService): Promise<TwilioClient> => {
  let client: TwilioClient = null;
  return new Promise<TwilioClient>(resolve => {
    _chatUserService.localUserChatToken$
      .pipe(filter(chatToken => chatToken && chatToken.length > 0))
      .subscribe(async (chatToken: string) => {
        debug('initialize twilio', chatToken);
        try {
          if (client) {
            await client.updateToken(chatToken);
            return;
          }
          client = await new TwilioClient(chatToken, { logLevel: 'error' });
          debug('... initialized twilio!');
          resolve(client);
        } catch (err) {
          console.error('twilio init error', err);
          resolve(null);
        }
      });
  });
};
