import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { IUser } from '../models/contracts/user';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private _httpClient: HttpClient) {}

  public async find(userId: string) {
    return new Promise<IUser>(resolve =>
      this._httpClient.get<IUser>(`/users/${userId}`).subscribe(resolve, error => {
        console.error(JSON.stringify(error));
        resolve(null);
      })
    );
  }
}
