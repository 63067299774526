<div class="button-container" [ngClass]="{ 'single-button': singleButton }">
  <button
    attr.aria-label="{{ ariaLabel }}"
    class="button"
    [disabled]="disabled"
    [ngClass]="{ 'single-button': singleButton }"
  >
    <ng-content class="text"></ng-content>
  </button>
</div>
