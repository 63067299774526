import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'itl-network-warning',
  templateUrl: './network-warning.component.html',
  styleUrls: ['./network-warning.component.scss'],
})
export class NetworkWarningComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
