import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Client as TwilioClient, Conversation } from '@twilio/conversations';

import { TWILIO_TOKEN } from './twilio.factory';

@Injectable({
  providedIn: 'root',
})
export class TwilioTypingIndicatorService {
  private _channel!: Conversation;

  private _typingSignalEvent = new Subject<boolean>();

  public get typingSignalEvent(): Observable<boolean> {
    return this._typingSignalEvent.asObservable();
  }

  constructor(@Inject(TWILIO_TOKEN) private readonly _twilioChatClient: Promise<TwilioClient>) {}

  public async subscribeToChannelEvents(channelSid: string): Promise<void> {
    const twilioClient = await this._twilioChatClient;

    this._channel = await twilioClient.getConversationBySid(channelSid);

    if (!this._channel) {
      return;
    }

    this._channel.on('typingStarted', member => this._typingSignalEvent.next(true));
    this._channel.on('typingEnded', member => this._typingSignalEvent.next(false));
  }

  public sendTypeIndicatorSignal(): void {
    if (!this._channel) {
      return;
    }

    this._channel.typing();
  }
}
