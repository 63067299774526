import { Injectable } from '@angular/core';
import { isString, last } from 'lodash';
import { combineLatest, from, Observable, Subject } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { Deferred } from 'src/app/common/deferred/deferred';
import makeDebug from 'src/makeDebug';
import { v4 } from 'uuid';

import { UserService } from '../user.service';
import { ChatConnectionStateService } from './chat-connection-state.service';
import { ChatMessagesStatusService } from './chat-messages-status.service';
import { ChatSendQueueService } from './chat-send-queue.service';
import { ChatSendService } from './chat-send.service';
import { ChatUserService } from './chat-user.service';
import { ChatDbService } from './data/chat-db.service';
import { ChatChannel, ChatMember, ChatMessage } from './data/db-schema';
import { ChatChannelDetail } from './model/chat.model';
import { TwilioChatEventSourceService } from './twillio/twilio-chat-event-source.service';
import { TwilioTypingIndicatorService } from './twillio/twilio-typing.service';
import { fetchMissingMessages } from './utils/missing-messages-finder';

const debug = makeDebug('services:chat:service');

export interface ChatChannelWithDetails extends ChatChannel {
  lastMessage: ChatMessage | null;
  unreadMessages: number;
  isActive: boolean;
}

@Injectable({ providedIn: 'root' })
export class ChatService {
  private userInfoCache: { [key: string]: { name: string; active: boolean } } = {};

  private _ready = new Deferred<void>();

  public get ready(): Promise<void> {
    return this._ready.promise;
  }

  private _typingSignalEvent = new Subject<boolean>();

  public get typingSignalEvent(): Observable<boolean> {
    return this._typingSignalEvent.asObservable();
  }

  constructor(
    private _chatDb: ChatDbService,
    private _chatUserService: ChatUserService,
    private _chatSendService: ChatSendService,
    private _usersService: UserService,
    private _connectionStateService: ChatConnectionStateService,
    private _chatMessagesStatus: ChatMessagesStatusService,
    /* imported to force init */
    private _chatSendQueue: ChatSendQueueService,
    private _twilioChatEventSourceService: TwilioChatEventSourceService,
    private _twilioTypingIndicatorService: TwilioTypingIndicatorService
  ) {
    this._twilioChatEventSourceService.ready.then(() => this._ready.resolve());
    this._twilioTypingIndicatorService.typingSignalEvent.subscribe(isTyping => this._typingSignalEvent.next(isTyping));
  }

  public observeIsOnline(): Observable<boolean> {
    return this._connectionStateService.observeOnlineState();
  }

  public async markChannelAsRead(channelSid: string, index: number) {
    debug('mark channel as read', { channelSid, index });
    await this._chatMessagesStatus.updateChannelConsumptionStatus(channelSid, index);
  }

  public async checkForNewMessagesInChannel(channelSid: string) {
    const lastMessage = await this._chatDb.getLastMessageOfChannel(channelSid);
    const channel = await this._chatDb.getChannel(channelSid);
    const lastLocalIndex = lastMessage ? lastMessage.index : -1;
    debug('check for new messages', {
      lastLocalIndex,
      lastMessageIndex: channel.lastMessageIndex,
      fetch: channel.lastMessageIndex > lastLocalIndex,
    });
    if (channel.lastMessageIndex > lastLocalIndex) {
      await this.fetchMessagesOfChannel(channel.sid, undefined, channel.lastMessageIndex - lastLocalIndex);
    }
  }

  public async fetchLastMessagesOfChannels() {
    const channels$ = await this._chatDb.getChannels();
    channels$.pipe(take(1)).subscribe(channels => {
      channels.forEach(async channel => {
        debug('fetch last message of channel', channel.sid);
        try {
          await this.fetchMessagesOfChannel(channel.sid, undefined, 1);
        } catch (error) {
          console.error(error);
          window.logger.error('Fetching messages of channel failed.', error);
        }
      });
    });
  }

  public async getChannels(): Promise<Observable<ChatChannelWithDetails[]>> {
    debug('get channels');
    const channels$ = await this._chatDb.getChannels();
    const enrichChannel = async (channel: ChatChannelWithDetails) => {
      debug('enrich channel', channel);
      try {
        const unreadMessages = await this._chatMessagesStatus.getUnreadMessagesOfChannel(channel.sid);
        channel.unreadMessages = unreadMessages;
        const sendQueue = await this._chatDb.getCurrentSendQueue(channel.sid);
        if (sendQueue.length > 0) {
          debug('channel has messages in queue. using them as last message');
          channel.lastMessage = last(sendQueue);
        } else {
          debug('no messages in send queue. get last message...', channel.sid);
          const lastMessage = await this._chatDb.getLastMessageOfChannel(channel.sid);
          if (lastMessage) {
            const userInfo = await this.getUserInfo(lastMessage.memberIdentity);
            lastMessage.authorName = userInfo.name;
            debug('channel has last message in chache. using it');
            channel.lastMessage = lastMessage;
            channel.isActive = userInfo.active;
          }
        }
        if (channel.uniqueName) {
          const user = await this.setFriendlyNameToRemoteUserName(channel);
          if (user) {
            channel.isActive = user.active;
          }
        } else {
          channel.isActive = true;
        }
      } catch (error) {
        window.logger.error('error with getMessageByIndex', error);
      }
      return channel;
    };
    return channels$.pipe(mergeMap(channels => from(Promise.all(channels.map(enrichChannel)))));
  }

  private async setFriendlyNameToRemoteUserName(
    channel: ChatChannel | ChatChannelWithDetails
  ): Promise<{
    name: string;
    active: boolean;
  }> {
    const userIdentity = await this._chatUserService.getUserIdentity();
    const memberIdentities = this.getMemberSidsFromUniqueName(channel.uniqueName);
    if (!memberIdentities) {
      return;
    }

    const remoteUserIdentity = memberIdentities.find(memberIdentity => memberIdentity !== userIdentity);
    if (remoteUserIdentity) {
      const remoteUserName = await this.getUserInfo(remoteUserIdentity);
      if (remoteUserName && remoteUserName.name) {
        channel.friendlyName = remoteUserName.name;
      }
      return remoteUserName;
    }
  }

  private getMemberSidsFromUniqueName(uniqueName: string) {
    if (uniqueName.indexOf('§') === -1) {
      return null;
    }

    return uniqueName.split('§');
  }

  public createChat(): Observable<string> {
    return this._chatSendService.createChat();
  }

  public async subscribeToChannelEvents(channelSid: string): Promise<void> {
    await this._twilioTypingIndicatorService.subscribeToChannelEvents(channelSid);
  }

  public sendTypeIndicatorSignal(): void {
    this._twilioTypingIndicatorService.sendTypeIndicatorSignal();
  }

  public async sendMessage(channelSid: string, message: string, attributes = {}) {
    debug('send message', { channelSid, message });
    const uuid = v4();
    const localUserIdentity = await this._chatUserService.getUserIdentity();
    const localUserMemberSid = await this._chatDb.getMemberSidForIdentity(channelSid, localUserIdentity);
    const newMessage = this.convertToMessage(
      uuid,
      localUserMemberSid,
      localUserIdentity,
      channelSid,
      message,
      attributes
    );
    await this._chatDb.insertMessage(newMessage);
    await this._chatDb.setChannelLastLocalUpdate(channelSid);
  }

  public async fetchMessagesOfChannel(channelSid: string, anchor: number, count = 100) {
    debug('load messages of channel', { channelSid, anchor, count });
    let normalizedAnchor = anchor;
    if (normalizedAnchor < 0) {
      normalizedAnchor = undefined;
    }
    const messages = await this._chatSendService.fetchMessagesOfChannel(channelSid, normalizedAnchor, count);

    const localUserIdentity = await this._chatUserService.getUserIdentity();
    for (const message of messages) {
      const isLocalUser = message.memberIdentity === localUserIdentity;
      message.isLocal = isLocalUser;
    }
    debug('...got messages in channel', channelSid, 'messages:', messages);
    await this._chatDb.bulkInsertMessages(messages);
    await this._chatDb.setChannelLastLocalUpdate(channelSid);
  }

  public async getChannelDetails(channel: ChatChannel): Promise<Observable<ChatChannelDetail>> {
    debug('get channel details');
    const enrichChannelMember = async (member: ChatMember) => {
      debug('enricht member', member);
      const userInfo = await this.getUserInfo(member.identity);
      return { ...member, friendlyName: userInfo.name, isActive: userInfo.active };
    };
    const enrichMessages = async (message: ChatMessage) => {
      const userInfo = await this.getUserInfo(message.memberIdentity);
      message.authorName = userInfo.name;
      return message;
    };
    if (channel.uniqueName) {
      await this.setFriendlyNameToRemoteUserName(channel);
    }

    return combineLatest([
      (await this._chatDb.getChannelMembers(channel.sid)).pipe(
        mergeMap(members => from(Promise.all(members.map(enrichChannelMember))))
      ),
      (await this._chatDb.getMessagesOfChannel(channel.sid)).pipe(
        mergeMap(messages => from(Promise.all(messages.map(enrichMessages))))
      ),
    ]).pipe(
      tap(([_members, messages]) => {
        setTimeout(() => fetchMissingMessages(messages, this.fetchMessagesOfChannel.bind(this)));
      }),
      map(([members, messages]) => {
        debug('map', { members, messages });
        const isOneOnOneChat = isString(channel.uniqueName) && channel.uniqueName !== '';
        // group chats are always active
        const isChannelActive = !isOneOnOneChat || members.every(member => member.isActive !== false);
        return {
          members,
          messages,
          channel,
          ...channel,
          isActive: isChannelActive,
        } as ChatChannelDetail;
      })
    );
  }

  private async getUserInfo(identity: string): Promise<{ name: string; active: boolean }> {
    const cachedUserInfo = this.userInfoCache[identity];
    if (cachedUserInfo) {
      return cachedUserInfo;
    }
    const user = await this._usersService.find(identity);
    if (!user) {
      return { name: '', active: false };
    }
    const name = `${user.firstName} ${user.lastName}`;
    this.userInfoCache[identity] = { name, active: user.active === false ? false : true };
    return this.userInfoCache[identity];
  }

  private convertToMessage(
    uuid: string,
    memberSid: string,
    memberIdentity: string,
    channelSid: string,
    body: string,
    attributes: any
  ): ChatMessage {
    return {
      _id: uuid,
      isLocal: true,
      memberSid,
      memberIdentity,
      channelSid,
      body,
      status: 'pending',
      timestamp: new Date().toISOString(),
      retries: 0,
      dateUpdated: new Date().toISOString(),
      attributes,
    } as ChatMessage;
  }
}
